<template>
  <div class="home">
    <v-row class="p-0 m-0" no-gutters>
      <v-col>
        <v-parallax src="img/figure/figure2.jpg" height="600">
          <v-row align="center" justify="center"> </v-row>
        </v-parallax>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Index",
  components: {},
};
</script>
